import styled from 'styled-components'
import Link from 'gatsby-link'
import theme, { media } from '../../helpers/theme'

const SideNav = styled.div`
  position: absolute;
  float: right;
  width: 250px;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 500;
  margin: 0;
  padding-top: ${theme.space[6]}px;
  display: unset;
  white-space: nowrap;
  background-color: ${theme.colors.font.black};
  transform: translateX(100vw);

  ${media('lg')} {
    display: none;
  }
`

SideNav.navContainer = styled.div`
  width: 100%;
  height: 500px;
`

SideNav.pageNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-bottom: 2px groove ${theme.colors.font.darkGray};

  div {
    padding-left: ${theme.space[10]}px;
  }
`

SideNav.link = styled(Link)`
  text-decoration: none;
`

SideNav.primaryOption = styled.div`
  margin-bottom: ${theme.space[2]}px;
  color: ${theme.colors.font.white};
  line-height: 28px;
  font-size: ${theme.font['base']};
  font-family: 'Open Sans';
`

SideNav.complianceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: ${theme.space[2]}px;
  height: 105px;
  border-bottom: 2px groove ${theme.colors.font.darkGray};
  div {
    padding-left: ${theme.space[10]}px;
  }
`

SideNav.secondaryOption = styled.div`
  margin-bottom: ${theme.space[2]}px;
  color: ${theme.colors.font.lightGray};
  line-height: 28px;
  font-size: ${theme.font['sm']};
  font-family: 'Open Sans';
`

SideNav.accountContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: ${theme.space[2]}px;
  height: 105px;
  div {
    padding-left: ${theme.space[10]}px;
  }
`

export default SideNav
