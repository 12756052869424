import React, { useState, useEffect } from 'react'
import BannerLayout from './banner.styles'
import ClearIcon from '@material-ui/icons/Clear'

const Banner = () => {
  const [open, setOpen] = useState(true)

  useEffect(() => {
    if (window.sessionStorage.getItem('bannerClosed')) setOpen(false)
  }, [])

  const onCloseClick = () => {
    if (typeof window !== `undefined`)
      window.sessionStorage.setItem('bannerClosed', true)
    setOpen(false)
  }

  return (
    <>
      {open && (
        <BannerLayout>
          <BannerLayout.text>
            Thanks for visiting! We hope you enjoy your Sitey experience. Just
            to let you know you visited one of our paid endorsements before
            arriving at our site.
          </BannerLayout.text>

          <BannerLayout.button onClick={onCloseClick}>
            <ClearIcon />
          </BannerLayout.button>
        </BannerLayout>
      )}
    </>
  )
}

export default Banner
