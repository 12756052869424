import styled, { keyframes } from 'styled-components'
import Link from 'gatsby-link'
import theme, { media } from '../../helpers/theme'
import MenuIcon from '@material-ui/icons/MenuTwoTone'

const Header = styled.div`
  position: ${(props) => (props.isWhiteLogo ? 'absolute' : 'relative')};
  width: 100%;
  box-shadow: ${(props) =>
    props.isWhiteLogo ? 'none' : `0 2px 2px 0 ${theme.colors.lightGray}`};
  z-index: 500;
  top: 0;
  left: 0;
  min-height: 100px;
  white-space: nowrap;
  margin-bottom: -${theme.space[4]}px;
  background-color: ${(props) =>
    props.isWhiteLogo ? 'none' : theme.colors.white};

  ${media('lg')} {
    margin-bottom: 0;
  }

  div {
    padding-top: ${theme.space[2]}px;
  }
`
Header.headerContainer = styled.div`
  padding-right: 0px;
  padding-left: 0px;
  margin: 0px;
  width: 100%;
  padding: 20px;

  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }
  ${media(1200)} {
    width: 100%;
  }
`
Header.logoContainer = styled.div`
  float: none;
  display: inline;
  ${media('lg')} {
    float: left;
  }
`

Header.logoLink = styled(Link)``

Header.logo = styled.img`
  width: 200px;
`

Header.buttonContainer = styled.div`
  display: none;
  float: right;
  align-items: center;
  ${media('lg')} {
    display: flex;
  }
  margin-top: ${theme.space[3]}px;
`
Header.mobileNavContainer = styled.span`
  align-items: center;
  float: right;
  display: inline;
  padding-top: ${theme.space[2]}px;
  ${media('lg')} {
    display: none;
  }
  &:hover {
    cursor: pointer;
  }
  color: ${(props) => (props.isWhiteLogo ? 'white' : 'black')};
  :hover :active :focus {
    background: rgba(0, 0, 0, 0.15);
  }
`

Header.menuIcon = styled(MenuIcon)``

Header.link = styled(Link)`
  padding: ${theme.space[4]}px ${theme.space[6]}px;
  color: ${(props) =>
    props.isWhiteLogo ? theme.colors.font.white : theme.colors.font.gray};
  font-size: ${theme.font['sm']};
  font-family: 'Open Sans';
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  :hover :active {
    background-color: rgba(0, 0, 0, 0.2);
  }
  :hover {
    cursor: pointer;
  }
`

Header.screenReaderText = styled.div.attrs(() => ({
  className: 'screen-reader-text',
  ariaLabel: 'Sitey Assistance Info',
}))`
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal;
`

const pressButton = keyframes`
0% {
  transform: 0;
}

100% {
  transform: translateY(0.5px);
}
`

Header.button = styled.a`
  margin: 0 ${theme.space[8]}px;
  border: none;
  border-radius: 2px;
  padding: ${theme.space[2]}px ${theme.space[10]}px;
  background-color: ${(props) => props.color || theme.colors.font.white};
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
  font-size: ${theme.font['sm']};
  color: ${theme.colors.font.white};
  font-family: 'Open Sans';
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: #75c36f;
  }

  &:active {
    background-color: #4aa244;
    animation-name: ${pressButton};
    animation-fill-mode: forwards;
  }
`

Header.popupContainer = styled.div`
  position:absolute;
  top:80px;
  z-index:501;
  width:100%;
  max-width:400px;
  left:50%;
  transform:translateX(-50%);

  @media(max-width:1023px){
    margin-left:250px;
  }
`

Header.callToActionContainer = styled.div`
  width:100%;
  max-width:400px;
  height: 210px;
  background: white;
  padding: 0px;
  z-index: 10;
  color: #000;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};

  @media (max-width:1023px){
    z-index:501;
  }
`

Header.callToActionBar = styled.div`
  width: 100%;
  height: 40px;
  background: #f5f5f5;
  padding: 0px;
  border-bottom: 1px solid #d3d3d3;
  text-align: end;
  padding-right: 15px;
  font-size: 17px;
  padding-top: 8px;
  font-weight: bold;
  cursor: pointer;
`

Header.callToActionContent = styled.div`
  width: 100%;
  padding: 10px 20px;
`

Header.callToActionDescription = styled.span`
  font-size: 15px;
  font-weight: bold;
  text-aling: start;
  display: block;
  margin-top: 10px;
  margin-bottom: 5px
`

Header.callToActionRedirectText = styled.span`
  font-size: 12px;
  display: block;
`

Header.callToActionImage = styled.img`
  width: 100%;
  max-width: 200px;
`

export default Header
