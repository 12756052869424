import styled from 'styled-components'
import theme, { media } from '../../helpers/theme'
import Link from 'gatsby-link'

const Footer = styled.div`
  padding: ${theme.space[10]}px 0;
  padding-bottom: ${theme.space[16]}px;
  position: relative;
  background-color: ${theme.colors.font.black};
  width: 100%;
  box-shadow: 0 500px 0 500px ${theme.colors.font.black};
  min-height: ${(props) => (props.isTallFooter ? '51vh' : 'none')};
`

Footer.footerContainer = styled.div`
  padding-right: ${theme.space[4]}px;
  padding-left: ${theme.space[4]}px;
  margin-right: auto;
  margin-left: auto;
  overflow: auto;

  ${media(992)} {
    width: 970px;
  }
  ${media(1200)} {
    width: 1170px;
  }
`

Footer.navContainer = styled.div`
  float: left;
  display: none;
  flex-direction: row;
  padding-left: -${theme.space[16]}px;

  ${media('md')} {
    display: flex;
  }
`

Footer.navColumn = styled.div`
  padding-left: ${theme.space[12]}px;
  display: flex;
  flex-direction: column;
`

Footer.navTitle = styled.span`
  color: ${theme.colors.font.white};
  font-size: ${theme.font['sm']};
  margin-bottom: ${theme.space[4]}px;
  text-transform: uppercase;
`

Footer.navSubOption = styled(Link)`
  color: ${theme.colors.font.lightGray};
  font-size: ${theme.font['xs']};
  margin-bottom: ${theme.space[2]}px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: ${theme.colors.font.white};
  }
`

Footer.personalInfoDesktop = styled.span`
  color: ${theme.colors.font.lightGray};
  font-size: ${theme.font['xs']};
  margin-top: ${theme.space[1]}px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    color: ${theme.colors.font.white};
  }
`

Footer.linkContainer = styled.div`
  display: flex;
  flex-direction: column;
`

Footer.logoLink = styled(Link)``

Footer.logoContainer = styled.div`
  float: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: none;
  ${media('md')} {
    display: flex;
    float: right;
    text-align: right;
    width: 7rem;
    margin-right: ${theme.space[8]}px;
    align-items: flex-start;
  }

  ${media(992)} {
    width: initial;
    align-items: center;
  }
`

Footer.logo = styled.img`
  width: 100px;
  margin: 0 auto ${theme.space[4]}px;
  opacity: 0.2;
  transition: opacity 0.2s;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`

Footer.copyright = styled.span`
  color: ${theme.colors.font.white};
  opacity: 0.2;
  font-size: ${theme.font['xs']};
`

Footer.personalInfoMobile = styled.span`
  display: initial;
  color: ${theme.colors.font.white};
  opacity: 0.2;
  font-size: ${theme.font['sm']};
  padding-top: ${theme.space[5]}px;
  padding-bottom: ${theme.space[6]}px;
  overflow: hidden;
  margin: 0 auto;

  &:hover {
    cursor: pointer;
    color: ${theme.colors.font.white};
  }

  ${media('md')} {
    display: none;
  }
`

export default Footer
