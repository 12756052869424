import React from 'react'
import Footer from './footer.styles'
import LogoWhite from '../../images/logo-white.svg'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

const FooterSection = ({ isTallFooter }) => {
  const isSamePage = (navigateTo) => {
    if (typeof window !== 'undefined' && location.pathname === navigateTo) {
      window.scrollTo(0, 0)
    }
  }

  return (
    <Footer isTallFooter={isTallFooter}>
      <Footer.footerContainer>
        <Footer.navContainer>
          <Footer.navColumn>
            <Footer.linkContainer>
              <Footer.navTitle>Learn More</Footer.navTitle>
              <Footer.navSubOption
                data-element-location={DataElementLocations.FOOTER}
                data-element-label={`sitey-footer-links-support`}
                data-element-id={`sitey-links-support`}
                target={'_blank'}
                to={process.env.GATSBY_SUPPORT_URL}
              >
                Support
              </Footer.navSubOption>
            </Footer.linkContainer>
            <Footer.linkContainer>
              <Footer.navSubOption
                data-element-location={DataElementLocations.FOOTER}
                data-element-label={`sitey-footer-links-login`}
                data-element-id={`sitey-links-login`}
                to={process.env.GATSBY_LOGIN_URL}
              >
                Log In
              </Footer.navSubOption>
            </Footer.linkContainer>
          </Footer.navColumn>
          <Footer.navColumn>
            <Footer.linkContainer>
              <Footer.navTitle>Company</Footer.navTitle>
              <Footer.navSubOption
                data-element-location={DataElementLocations.FOOTER}
                data-element-label={`sitey-footer-links-terms`}
                data-element-id={`sitey-links-terms`}
                onClick={() => isSamePage('/terms')}
                to={'/terms'}
              >
                Terms of Service
              </Footer.navSubOption>
            </Footer.linkContainer>
            <Footer.linkContainer>
              <Footer.navSubOption
                data-element-location={DataElementLocations.FOOTER}
                data-element-label={`sitey-footer-links-privacy`}
                data-element-id={`sitey-links-privacy`}
                target='_blank'
                href={'https://newfold.com/privacy-center '}
              >
                Privacy Notice
              </Footer.navSubOption>
            </Footer.linkContainer>
            <Footer.linkContainer>
              <Footer.navSubOption
                data-element-location={DataElementLocations.FOOTER}
                data-element-label={`sitey-footer-links-abuse`}
                data-element-id={`sitey-links-abuse`}
                onClick={() => isSamePage('/abuse')}
                to={'/abuse'}
              >
                Abuse
              </Footer.navSubOption>
            </Footer.linkContainer>
            <Footer.linkContainer>
              <Footer.navSubOption
                data-element-location={DataElementLocations.FOOTER}
                data-element-label={`sitey-footer-links-personal-info`}
                data-element-id={`sitey-links-personal-info-desktop`}
                target='_blank'
                href={'https://www.newfold.com/privacy-center/addendum-for-california-users'}
              >
                <Footer.personalInfoDesktop>
                  Do Not Sell My Personal Information
                </Footer.personalInfoDesktop>
              </Footer.navSubOption>
            </Footer.linkContainer>

            <Footer.linkContainer>
              <Footer.navSubOption
                data-element-location={DataElementLocations.FOOTER}
                data-element-label={`sitey-footer-cookie-settings`}
                data-element-id={`sitey-links-cookie`} 
                 className={"ot-sdk-show-settings"}      
              >
                Cookie Settings
              </Footer.navSubOption>
            </Footer.linkContainer>

          </Footer.navColumn>
        </Footer.navContainer>
        <Footer.logoContainer>
          <Footer.logoLink
            data-element-location={DataElementLocations.FOOTER}
            data-element-label='footer.logo'
            data-element-id='sitey-footer-logo'
            onClick={() => isSamePage('/')}
            to={'/'}
          >
            <Footer.logo src={LogoWhite} />
          </Footer.logoLink>
          <Footer.copyright>
            5335 Gate Pkwy, 2nd Floor, <br />
            Jacksonville, FL 32256 <br />© Sitey. All Rights Reserved
          </Footer.copyright>
          <Footer.navSubOption
            data-element-location={DataElementLocations.FOOTER}
            data-element-label={`sitey-footer-links-personal-info`}
            data-element-id={`sitey-links-personal-info-mobile`}
            href={'https://www.newfold.com/privacy-center/addendum-for-california-users'}
          >
            <Footer.personalInfoMobile>
              Do Not Sell My Personal Information
            </Footer.personalInfoMobile>
          </Footer.navSubOption>
          <Footer.navSubOption
            data-element-location={DataElementLocations.FOOTER}
            data-element-label={`sitey-footer-links-cookie-settings`}
            data-element-id={`sitey-links-cookie-settings-mobile`}
            className={'ot-sdk-show-settings'}
            
          >
            <Footer.personalInfoMobile>
              Cookie Settings
            </Footer.personalInfoMobile>
          </Footer.navSubOption>

        </Footer.logoContainer>
      </Footer.footerContainer>
    </Footer>
  )
}

export default FooterSection
