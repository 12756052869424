import styled from 'styled-components'
import { theme } from './../../helpers/theme'
import IconButton from '@material-ui/core/IconButton'

const Banner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 50px;
  color: ${theme.colors.font.white};
  background-color: ${theme.colors.gray};
  position: fixed;
  bottom: 0;
  opacity: 80%;
  z-index: 1;
`

const Text = styled.p`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 10px;
  text-align: center;
  font-size: ${theme.font.xs};
`

const CloseButton = styled(IconButton)`
  display: flex !important;
  justify-self: flex-end !important;
  color: ${theme.colors.font.white} !important;
`

Banner.text = Text
Banner.button = CloseButton
export default Banner
