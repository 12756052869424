const theme = {
  colors: {
    black: '#19191A',
    white: '#ffffff',
    background: '#f5f5f5',
    darkBlue: '#39598e',
    green: '#63ba5c',
    gray: '#888888',
    lightGray: '#CCCCCC',
    fadedGray: '#f5f5f5',
    shadow: '#e2e2e2',
    font: {
      white: '#ffffff',
      black: '#2b2b2b',
      gray: '#808080',
      darkGray: '#686868',
      lightGray: '#9b9b9b',
      green: '#63ba5c',
      darkGreen: '#008000',
    },
  },
  space: {
    '1px': 1,
    1: 4,
    2: 8,
    3: 12,
    4: 16,
    5: 20,
    6: 24,
    8: 32,
    10: 40,
    12: 48,
    16: 64,
    20: 80,
    24: 96,
    32: 128,
    40: 160,
    48: 192,
    56: 224,
    64: 256,
  },
  font: {
    xs: '.75rem',
    sm: '.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '4rem',
  },
  breakpoints: {
    xs: 375,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1440,
  },
}

const media = (breakpoint) =>
  `@media (min-width: ${theme.breakpoints[breakpoint] || breakpoint}px)`

export default theme
export { theme, media }
