import React, { useState, useEffect } from 'react'
import Header from './header.styles'
import LogoWhite from '../../images/logo.webp'
import LogoPrimary from '../../images/logo.webp'
import theme from '../../helpers/theme'
import PropTypes from 'prop-types'
import SideNav from './sidenav.styles'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import PopPup from '../pop-pup'

const HeaderSection = ({ handleShift, isWhiteLogo = false }) => {
  const [open, setOpen] = useState(true)
  const [callToAction, setCallToAction] = useState(false)

  const handleClick = () => {
    handleShift(open)
    setOpen(!open)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', checkSize)

      return () => {
        window.removeEventListener('resize', checkSize)
      }
    }
  }, [])

  const redirectWebdotcom = (websiteUrl) => {
    setTimeout(() => {
      document.location.href = websiteUrl
      setCallToAction(false)
  }, 5000)
  }

  const redirectWebDotComDisplayBanner = (websiteUrl)=>{
    setCallToAction(true)
    redirectWebdotcom(websiteUrl)
  }


  const checkSize = () => {
    if (open) {
      if (window.innerWidth > parseInt(`${theme.breakpoints['lg']}`) - 1) {
        handleClick()
      }
    }
  }

  return (
    <>
      <Header isWhiteLogo={isWhiteLogo}>
        <Header.headerContainer id={'TOP'}>
          <Header.logoContainer>
            <Header.logoLink
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.logo'
              data-element-id='sitey-header-logo'
              to='/'
            >
              {isWhiteLogo ? (
                <Header.logo src={LogoWhite} alt='Sitey Logo' />
              ) : (
                <Header.logo src={LogoPrimary} alt='Sitey Logo' />
              )}
            </Header.logoLink>
          </Header.logoContainer>
          <Header.screenReaderText>
            If you are using assistive technology and are unable to read any
            part of the Sitey.com website, or otherwise have difficulties using
            the Sitey.com website, please call 844-211-7911 and our customer
            service team will assist you.
          </Header.screenReaderText>
          <Header.buttonContainer>
            <Header.link
              isWhiteLogo={isWhiteLogo}
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.websites'
              data-element-id='sitey-header-websites'
              target='_blank'
            >
            <span onClick={() => {
              setCallToAction(true), redirectWebdotcom('https://www.web.com/websites')
            }}>
              Websites
            </span>
            </Header.link>
            <Header.link
              isWhiteLogo={isWhiteLogo}
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.marketing'
              data-element-id='sitey-header-marketing'
              target='_blank'
            >
              <span onClick={() => {
                setCallToAction(true), redirectWebdotcom('https://www.web.com/online-marketing')
              }}>
                Marketing
              </span>
            </Header.link>
            <Header.link
              isWhiteLogo={isWhiteLogo}
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.domains'
              data-element-id='sitey-header-domains'
              target='_blank'
            >
              <span onClick={() => {
                  setCallToAction(true), redirectWebdotcom('https://www.web.com/domains')
                }}>
                  Domains
                </span>
            </Header.link>
            <Header.link
              isWhiteLogo={isWhiteLogo}
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.hosting'
              data-element-id='sitey-header-hosting'
              target='_blank'
            >
              <span onClick={() => {
                  setCallToAction(true), redirectWebdotcom('https://www.web.com/websites/hosting')
              }}>
                Hosting
              </span>
            </Header.link>
            <Header.link
              isWhiteLogo={isWhiteLogo}
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.security'
              data-element-id='sitey-header-security'
              target='_blank'
            >
              <span onClick={() => {
                setCallToAction(true), redirectWebdotcom('https://www.web.com/website-security')
              }}>
                Security
              </span>
            </Header.link>
            <Header.link
              isWhiteLogo={isWhiteLogo}
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.email'
              data-element-id='sitey-header-email'
              target='_blank'
            >
              <span onClick={() => {
                setCallToAction(true), redirectWebdotcom('https://www.web.com/email-service')
              }}>
                Email
              </span>
            </Header.link>
            <Header.link
              isWhiteLogo={isWhiteLogo}
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.expert.services'
              data-element-id='sitey-header-expert-services'
              target='_blank'
            >
              <span onClick={() => {
                setCallToAction(true), redirectWebdotcom('https://www.web.com/websites/pro-website-services')
              }}>
                Expert Services
              </span>
            </Header.link>
            <Header.link
              isWhiteLogo={isWhiteLogo}
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.login'
              data-element-id='sitey-header-login'
              onClick={() => setOpen(false)}
              to={process.env.GATSBY_LOGIN_URL}
            >
                Log In
            </Header.link>
            <Header.button
              color={theme.colors.green}
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.signup'
              data-element-id='sitey-header-signup'
            >
            <span onClick={() => {
                setCallToAction(true), redirectWebdotcom('https://www.web.com/websites/online-store-builder#packages')
              }}>
                Sign Up
              </span>
            </Header.button>
          </Header.buttonContainer>
          <Header.mobileNavContainer isWhiteLogo={isWhiteLogo}>
            <Header.menuIcon style={{ fontSize: 50 }} onClick={handleClick} />
          </Header.mobileNavContainer>
        </Header.headerContainer>
      </Header>
      <SideNav>
        <SideNav.navContainer>
          <SideNav.pageNavContainer>
            <SideNav.link
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.home'
              data-element-id='sitey-header-home-mobile'
              to='/'
            >
              <SideNav.primaryOption>Home</SideNav.primaryOption>
            </SideNav.link>
            <SideNav.link
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.websites'
              data-element-id='sitey-header-websites-mobile'
              to='#'
              onClick={() => redirectWebDotComDisplayBanner('https://www.web.com/websites')}
            >
              <SideNav.primaryOption>Websites</SideNav.primaryOption>
            </SideNav.link>
            <SideNav.link
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.marketing'
              data-element-id='sitey-header-marketing-mobile'
              to='#'
              onClick={() => redirectWebDotComDisplayBanner('https://www.web.com/online-marketing')}
            >
              <SideNav.primaryOption>Marketing</SideNav.primaryOption>
            </SideNav.link>
            <SideNav.link
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.domains'
              data-element-id='sitey-header-domains-mobile'
              
              to='#'
              onClick={() => redirectWebDotComDisplayBanner('https://www.web.com/domains')}
            >
              <SideNav.primaryOption >Domains</SideNav.primaryOption>
            </SideNav.link>
            <SideNav.link
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.hosting'
              data-element-id='sitey-header-hosting-mobile'
              to='#'
              onClick={() => redirectWebDotComDisplayBanner('https://www.web.com/websites/hosting')}
            >
              <SideNav.primaryOption>Hosting</SideNav.primaryOption>
            </SideNav.link>
            <SideNav.link
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.security'
              data-element-id='sitey-header-security-mobile'
              
              to='#'
              onClick={() => redirectWebDotComDisplayBanner('https://www.web.com/website-security')}
            >
              <SideNav.primaryOption>Security</SideNav.primaryOption>
            </SideNav.link>
            <SideNav.link
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.email'
              data-element-id='sitey-header-email-mobile'
              to='#'
              onClick={() => redirectWebDotComDisplayBanner('https://www.web.com/email-service')}
            >
              <SideNav.primaryOption>Email</SideNav.primaryOption>
            </SideNav.link>
            <SideNav.link
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.expert.services'
              data-element-id='sitey-header-expert-services-mobile'
              to='#'
              onClick={() => redirectWebDotComDisplayBanner('https://www.web.com/websites/pro-website-services')}
            >
              <SideNav.primaryOption>Expert Services</SideNav.primaryOption>
            </SideNav.link>
          </SideNav.pageNavContainer>
          <SideNav.complianceContainer>
            <SideNav.link
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.terms'
              data-element-id='sitey-header-terms'
              href="/terms"
            >
              <SideNav.secondaryOption>
                Terms of Service
              </SideNav.secondaryOption>
            </SideNav.link>
            <SideNav.link
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.privacy'
              data-element-id='sitey-header-privacy'
              onClick={() => redirectWebDotComDisplayBanner('https://newfold.com/privacy-center ')}
            >
              <SideNav.secondaryOption>Privacy Notice</SideNav.secondaryOption>
            </SideNav.link>
          </SideNav.complianceContainer>
          <SideNav.accountContainer>
            <SideNav.link
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.sidenav.login'
              data-element-id='sitey-header-sidenav.login'
              onClick={() => setOpen(false)}
              to={process.env.GATSBY_LOGIN_URL}
            >
              <SideNav.secondaryOption>Log In</SideNav.secondaryOption>
            </SideNav.link>
            <SideNav.link
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.sidenav.signup'
              data-element-id='sitey-header-sidenav.signup'
              to='#'
              onClick={() => redirectWebDotComDisplayBanner('https://www.web.com/websites/online-store-builder#packages')}
            >
              <SideNav.secondaryOption>Sign Up</SideNav.secondaryOption>
            </SideNav.link>
          </SideNav.accountContainer>
        </SideNav.navContainer>
      </SideNav>
      <Header.popupContainer>
        <PopPup callToAction={callToAction} setCallToAction={setCallToAction} sidenav/>
      </Header.popupContainer>
    </>
  )
}

HeaderSection.propTypes = {
  handleShift: PropTypes.func.isRequired,
  isWhiteLogo: PropTypes.bool,
}

export default HeaderSection
